<template>
    <div class="bl-rango">
        <v-row>
          <v-col cols="12">
            <v-date-picker
              locale="es-co"
              first-day-of-week="1"
              range
              v-model="date"
              full-width
              color="primary"
              :min="min"
              scrollable
            ></v-date-picker>
          </v-col>
          <v-col right>
            <v-btn
              dark
              color="grey"
              :loading="loading"
              :disabled="loading"
              class="mr-2"
              @click="desbloquearFechas()"
            >
              Desbloquear
            </v-btn>
            <v-btn
              :dark="!loading"
              :color="!loading ? 'black' : 'gray'"
              :loading="loading"
              :disabled="loading"
              @click="bloquearFechas()"
            >
              Bloquear
            </v-btn>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import * as moment from 'moment'
moment.locale('es')
export default {
    props: ["horarios", "profesional_id"],
    data: () => ({
    date: [],
    min: moment().format("YYYY-MM-DD"),
    bloqueo: true,
    loading: false,
    horaSelected: [],
  }),
  methods:{
      bloquearFechas() {
      if (this.profesional_id != null && this.date.length == 2) {
        this.loading = true;
        this.date = this.date.sort();
        const body = {
          route: "/bloquear_fechas",
          data: {
            dates: this.date,
            profesional: [this.profesional_id],
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.date = [moment().format("YYYY-MM-DD")];
              this.$store.dispatch("setAlert", alert);
              this.$emit("update_horas");
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text:
            "Debes seleccionar algun profesional y/o ambas fechas para bloquear",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
    desbloquearFechas() {
      if (this.profesional_id != null && this.date.length == 2) {
        this.loading = true;
        this.date = this.date.sort();
        const body = {
          route: "/desbloquear_fechas",
          data: {
            dates: this.date,
            profesional: [this.profesional_id],
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.date = [moment().format("YYYY-MM-DD")];
              this.$store.dispatch("setAlert", alert);
              this.$emit("update_horas");
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text:
            "Debes seleccionar algun profesional y/o ambas fechas para bloquear",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
  }
}
</script>