<template>
  <div class="fecha-multiple-bl">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row>
          <v-col cols="12" md="5" sm="6">
            <v-card height="500">
              <v-card-text>
                <v-date-picker
                  locale="es-co"
                  first-day-of-week="1"
                  v-model="date"
                  full-width
                  color="primary"
                  :min="min"
                ></v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="6">
            <v-card height="500">
              <v-card-subtitle style="padding: 5px 16px !important;">
                <v-row align="center" no-gutters>
                  <v-col>
                    <v-switch label="Bloquear" v-model="bloqueo">
                      <template #prepend>
                        <v-label>Desbloquear</v-label>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="primary"
                            >mdi-checkbox-blank-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Disponible</span>
                    </v-tooltip>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="grey">mdi-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Bloqueado</span>
                    </v-tooltip>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="blue-grey darken-4">mdi-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Hora seleccionada</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-title style="padding: 5px 16px !important;">
                Horas por bloquear
                <v-spacer></v-spacer>
                <v-btn
                  small
                  :dark="!loading"
                  :color="!loading ? 'black' : 'gray'"
                  :loading="loading"
                  :disabled="loading"
                  @click="desbloquearAg()"
                  v-if="!bloqueo"
                >
                  Desbloquear
                </v-btn>
                <v-btn
                  small
                  :dark="!loading"
                  :color="!loading ? 'black' : 'gray'"
                  :loading="loading"
                  :disabled="loading"
                  @click="bloquearAg()"
                  v-if="bloqueo"
                >
                  Bloquear
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-alert
                  type="warning"
                  v-if="apertura.inicio == null"
                >
                  El centro no cuenta con agenda para este dia
                </v-alert>
                <div
                  v-else
                  style="height: 350px;overflow: hidden;overflow-y: scroll;"
                >
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="(hora, index) in agenda"
                      :key="index"
                    >
                      <v-btn
                        width="100px"
                        outlined
                        small
                        color="primary"
                        v-if="hora.estado == 0"
                        @click="seleccion(hora)"
                        >{{ hora.hora }}</v-btn
                      >
                      <v-tooltip top v-if="hora.estado == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            width="100px"
                            small
                            dark
                            color="blue-grey darken-4"
                            @click="seleccion(hora)"
                            >{{ hora.hora }}</v-btn
                          >
                        </template>
                        <span>Hora por bloquear</span>
                      </v-tooltip>
                      <v-tooltip top v-if="hora.estado == 2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            width="100px"
                            small
                            dark
                            color="grey"
                            @click="seleccion(hora)"
                            >{{ hora.hora }}</v-btn
                          >
                        </template>
                        <span>Hora bloqueada</span>
                      </v-tooltip>
                      <v-tooltip top v-if="hora.estado == 3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            width="100px"
                            small
                            dark
                            color="purple"
                            >{{ hora.hora }}</v-btn
                          >
                        </template>
                        <span>Cita pendiente</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["horarios", 'profesionales'],
  data: () => ({
    bloqueo: true,
    date: moment().format("YYYY-MM-DD"),
    min: moment().format("YYYY-MM-DD"),
    loading: false,
    horaSelected: [],
    temporal: [],
  }),
  methods: {
    seleccion(hora) {
      let index = this.horaSelected.findIndex(
        (item) => item.hora === hora.hora
      );
      if (index > -1) {
        this.horaSelected.splice(index, 1);
      } else {
        this.horaSelected.push(hora);
      }
    },
    bloquearAg() {
      if (this.profesionales.length > 0 && this.horaSelected.length > 0) {
        this.loading = true;
        const horas = this.horaSelected.map((hora) => {
          return moment(hora.hora, "h:mm A").format("HH:mm");
        });
        const body = {
          route: "/bloqueo_multiple",
          data: {
            date: [this.date],
            profesionales: this.profesionales,
            horas: horas,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.temporal = this.horaSelected;
              this.horaSelected = [];
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text: "Debes seleccionar algun profesional y/o alguna hora",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
    desbloquearAg() {
      if (this.profesionales.length > 0 && this.horaSelected.length > 0) {
        const horas = this.horaSelected.map((hora) => {
          return moment(hora.hora, "h:mm A").format("HH:mm");
        });
        const body = {
          route: "/desbloqueo_multiple",
          data: {
            date: [this.date],
            profesionales: this.profesionales,
            horas: horas,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.horaSelected = [];
              this.temporal = [];
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.temporal = [];
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text: "Debes seleccionar algun profesional y/o alguna hora",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
  },
  computed: {
    apertura() {
      let horarios = { inicio: null, fin: null };
      let date = this.date
      if (moment(date).isoWeekday() == 7) {
        horarios.inicio = this.horarios.inicio_d;
        horarios.fin = this.horarios.fin_d;
      }

      if (moment(date).isoWeekday() == 6) {
        horarios.inicio = this.horarios.inicio_s;
        horarios.fin = this.horarios.fin_s;
      }

      if (moment(date).isoWeekday() <= 5) {
        horarios.inicio = this.horarios.inicio_lv;
        horarios.fin = this.horarios.fin_lv;
      }
      return horarios;
    },
    agenda() {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let actual = moment(moment().format("HH:mm"), "HH:mm");
      let start = moment(this.apertura.inicio, "HH:mm");
      let end = moment(this.apertura.fin, "HH:mm");
      if (this.apertura.inicio == null) {
        start = moment(this.horarios.inicio_lv, "HH:mm");
        end = moment(this.horarios.fin_lv, "HH:mm");
      }
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });
      rangeArray.forEach((hora) => {
        let index_temp = this.temporal.findIndex(
          (item) => item.hora === hora.hora
        );
        if (index_temp > -1) {
          hora.estado = 2;
        }

        let index = this.horaSelected.findIndex(
          (item) => item.hora === hora.hora
        );
        if (index > -1) {
          hora.estado = 1;
        }
      });
      return rangeArray;
    },
  },
};
</script>
