<template>
  <div class="bloqueoIndividual">
    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="6"
        v-for="(profesional, index) in profesionales"
        :key="index"
      >
        <v-card>
          <v-list-item>
            <v-list-item-avatar>
              <img
                :src="
                  $store.state.centro.route +
                    'profesionales/' +
                    profesional.foto
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ profesional.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ profesional.apodo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox
                v-model="profesionalSelected"
                :value="profesional.id"
              ></v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" color="black" height="30">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        Fecha
      </v-tab>
      <v-tab href="#tab-2">
        Fechas multiples
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
    <v-tab-item value="tab-1">
      <fecha :horarios="horarios" :profesionales="profesionalSelected"/>
    </v-tab-item>
    <v-tab-item value="tab-2">
      <multiple :horarios="horarios" :profesionales="profesionalSelected"/>
    </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Fecha from '../components/bloqueos/multiple/Fecha.vue'
import Multiple from '../components/bloqueos/multiple/Multiple.vue'
export default {
  name: "bloqueoFechas",
  props: ["horarios", "profesionales"],
  components:{
    Fecha,
    Multiple
  },
  data: () => ({
    tab: "tab-1",
    profesionalSelected: [],
  }),
};
</script>
