<template>
  <div class="bloqueos mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Bloquear agenda
          </v-card-title>
          <v-card-subtitle>
            En este apartado podrás bloquear la agenda de tus profesionales.
            <br />
            <strong
              >La agenda, con las horas ya bloqueadas y con citas pendientes
              solo será visible en el bloqueo individual por fecha.</strong
            >
          </v-card-subtitle>
          <v-card-text>
            <v-tabs v-model="tab">
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                Bloqueo individual
              </v-tab>
              <v-tab href="#tab-2">
                Bloqueo multiple
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <bloqueo-individual
                  :horarios="horarios"
                  :profesionales="profesionales"
                  :isLoading="isLoading"
                />
              </v-tab-item>
              <v-tab-item value="tab-2">
                <bloqueo-fechas
                  :horarios="horarios"
                  :profesionales="profesionales"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import bloqueoIndividual from "../../components/BloqueoIndividual.vue";
import bloqueoFechas from "../../components/BloqueoFechas.vue";

export default {
  name: "bloqueos",
  components: {
    bloqueoIndividual,
    bloqueoFechas,
  },
  data: () => ({
    tab: null,
    isLoading: false,
    profesionales: [],
    horarios: {
      inicio_d: "",
      fin_d: "",
      inicio_s: "",
      fin_s: "",
      inicio_lv: "",
      fin_lv: "",
    },
  }),
  methods: {
    loadHorarios() {
      const body = {
        route: "/sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.horarios = JSON.parse(response.data.data.horarios);
        }
      });
    },
    loadProfesionales() {
      this.isLoadingS = true;
      const body = {
        route: "/profesionales_disponibles",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.profesionales = response.data.data;
          }
        })
        .finally(() => (this.isLoadingS = false));
    },
  },
  created() {
    this.loadHorarios();
    this.loadProfesionales();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["bloqueos"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
