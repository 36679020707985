<template>
  <div class="bl-multiple">
    <v-row>
      <v-col cols="12" md="5" sm="6">
        <v-card height="500">
          <v-date-picker
            locale="es-co"
            first-day-of-week="1"
            v-model="date"
            full-width
            color="primary"
            :min="min"
            scrollable
            multiple
          ></v-date-picker>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" sm="6">
        <v-card>
          <v-card-subtitle style="padding: 5px 16px !important;">
            <v-row align="center" no-gutters>
              <v-col>
                <v-switch label="Bloquear" v-model="bloqueo">
                  <template #prepend>
                    <v-label>Desbloquear</v-label>
                  </template>
                </v-switch>
              </v-col>
              <v-col>
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="primary"
                        >mdi-checkbox-blank-circle-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Disponible</span>
                </v-tooltip>
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="blue-grey darken-4">mdi-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Hora seleccionada</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title style="padding: 5px 16px !important;">
            Horas por bloquear
            <v-spacer></v-spacer>
            <v-btn
              small
              :dark="!loading"
              :color="!loading ? 'black' : 'gray'"
              :loading="loading"
              :disabled="loading"
              v-if="!bloqueo"
              @click="desbloquearAg()"
            >
              Desbloquear
            </v-btn>
            <v-btn
              small
              :dark="!loading"
              :color="!loading ? 'black' : 'gray'"
              :loading="loading"
              :disabled="loading"
              v-if="bloqueo"
              @click="bloquearAg()"
            >
              Bloquear
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" v-for="(hora, index) in agenda" :key="index">
                <v-btn
                  width="100px"
                  outlined
                  small
                  color="primary"
                  v-if="hora.estado == 0"
                  @click="seleccion(hora)"
                >
                  {{ hora.hora }}
                </v-btn>
                <!-- Hora seleccionada -->
                <v-tooltip top v-if="hora.estado == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      width="100px"
                      small
                      dark
                      color="blue-grey darken-4"
                      @click="seleccion(hora)"
                      >{{ hora.hora }}</v-btn
                    >
                  </template>
                  <span>Hora por bloquear</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  props: ["horarios", "profesional_id"],
  data: () => ({
    date: [moment().format("YYYY-MM-DD")],
    min: moment().format("YYYY-MM-DD"),
    bloqueo: true,
    loading: false,
    horaSelected: [],
  }),
  methods: {
    // Calcular tiemp de duracion del servicio por agendar
    iteracion(inicio, fin) {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(inicio, "HH:mm");
      let end = moment(fin, "HH:mm").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return hora;
      });
      return rangeArray;
    },
    seleccion(hora) {
      let index = this.horaSelected.findIndex(
        (item) => item.hora === hora.hora
      );
      console.log(index);
      if (index > -1) {
        this.horaSelected.splice(index, 1);
      } else {
        this.horaSelected.push(hora);
      }
    },
    bloquearAg() {
      if (this.profesional_id != null && this.horaSelected.length > 0) {
        this.loading = true;
        const horas = this.horaSelected.map((hora) => {
          return moment(hora.hora, "h:mm A").format("HH:mm");
        });
        const body = {
          route: "/bloqueo_multiple",
          data: {
            date: this.date,
            profesionales: [this.profesional_id],
            horas: horas,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.horaSelected = [];
              this.$emit("update_horas");
            }
          })
          .catch((error) => {
            this.loading = false;
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text: "Debes seleccionar algun profesional y/o alguna hora",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
    desbloquearAg() {
      if (this.profesional_id != null && this.horaSelected.length > 0) {
        const horas = this.horaSelected.map((hora) => {
          return moment(hora.hora, "h:mm A").format("HH:mm");
        });
        const body = {
          route: "/desbloqueo_multiple",
          data: {
            date: this.date,
            profesionales: [this.profesional_id],
            horas: horas,
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              this.horaSelected = [];
              this.$emit("update_horas");
            }
          })
          .catch((error) => {
            this.loading = false;
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      } else {
        let alert = {
          alert: true,
          color: "warning",
          text: "Debes seleccionar algun profesional y/o alguna hora",
        };
        this.$store.dispatch("setAlert", alert);
      }
    },
  },
  computed: {
    apertura() {
      let horarios = { inicio: null, fin: null };
      let date = this.date[0];
      if (moment(date).isoWeekday() == 7) {
        horarios.inicio = this.horarios.inicio_d;
        horarios.fin = this.horarios.fin_d;
      }

      if (moment(date).isoWeekday() == 6) {
        horarios.inicio = this.horarios.inicio_s;
        horarios.fin = this.horarios.fin_s;
      }

      if (moment(date).isoWeekday() <= 5) {
        horarios.inicio = this.horarios.inicio_lv;
        horarios.fin = this.horarios.fin_lv;
      }
      return horarios;
    },
    agenda() {
      let start = moment(this.apertura.inicio, "HH:mm");
      let end = moment(
        moment(this.apertura.fin, "HH:mm")
          .add(parseInt(this.$store.state.centro.agenda), "minutes")
          .format("HH:mm"),
        "HH:mm"
      );
      if (this.apertura.inicio == null) {
        start = moment(this.horarios.inicio_lv, "HH:mm");
        end = moment(
          moment(this.horarios.fin_lv, "HH:mm")
            .add(parseInt(this.$store.state.centro.agenda), "minutes")
            .format("HH:mm"),
          "HH:mm"
        );
      }

      let rangeArray = this.iteracion(
        start.format("HH:mm"),
        end.format("HH:mm")
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });

       rangeArray.forEach((hora) => {
        let index = this.horaSelected.findIndex(
          (item) => item.hora === hora.hora
        );
        if (index > -1) {
          hora.estado = 1;
        }
      });
      return rangeArray;
    },
  },
};
</script>
