<template>
  <div class="bloqueoIndividual">
    <v-col cols="4" class="mt-2">
      <v-autocomplete
        dense
        outlined
        rounded
        v-model="profesionalSelected"
        :loading="isLoading"
        append-icon="mdi-magnify"
        item-text="nombre"
        :items="profesionales"
        :filter="customFilter"
        label="Selecciona un profesional"
        return-object
      ></v-autocomplete>
    </v-col>
    <v-tabs v-model="tab" color="black" height="30">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        Fecha
      </v-tab>
      <v-tab href="#tab-2">
        Fechas multiples
      </v-tab>
      <v-tab href="#tab-3">
        Rango de fecha especifico
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <fecha
          :horarios="horarios"
          :horas="horas"
          :profesional_id="!profesionalSelected ? null : profesionalSelected.id"
          v-on:set_date="set_date($event)"
          v-on:update_horas="update()"
        />
      </v-tab-item>
      <v-tab-item value="tab-2">
        <multiple
          :horarios="horarios"
          :profesional_id="!profesionalSelected ? null : profesionalSelected.id"
          v-on:update_horas="update()"
        />
      </v-tab-item>
      <v-tab-item value="tab-3">
        <rango
          :horarios="horarios"
          :profesional_id="!profesionalSelected ? null : profesionalSelected.id"
          v-on:update_horas="update()"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import * as moment from "moment";
import Fecha from "./bloqueos/Fecha.vue";
import Multiple from "./bloqueos/Multiple.vue";
import Rango from "./bloqueos/Rango.vue";
export default {
  name: "bloqueoIndividual",
  components: {
    Fecha,
    Multiple,
    Rango,
  },
  props: ["horarios", "profesionales", "isLoading"],
  data: () => ({
    tab: "tab-1",
    horas: { bloqueos: [], agenda: [] },
    profesionalSelected: null,
    date: moment().format("YYYY-MM-DD"),
  }),
  methods: {
    customFilter(item, queryText, itemText) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const text = removeAccents(queryText);
      const textOne = item.nombre.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    loadHoras() {
      if (this.profesionalSelected != null) {
        const body = {
          route: "/tiempo_agendado",
          params: {
            date: this.date,
          },
        };
        this.$store.dispatch("get_data", body).then((response) => {
          if (response.data.success) {
            this.horas = response.data.data;
          }
        });
      }
    },
    set_date(input) {
      this.date = input;
      this.loadHoras();
    },
    update() {
      this.loadHoras();
    },
  },
  watch: {
    profesionalSelected(val) {
      this.loadHoras();
      return this.profesionalSelected;
    },
  },
};
</script>
